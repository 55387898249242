$('.contact-header').click(function () {
    var isMobile = (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
    if (!isMobile) {
        $('#wcbox_tab').trigger('click');
        if ($(this).attr('id') !== 'contacto') {
            $('html, body').animate({
                scrollTop: $('#mainContainer').offset().top
            }, 2000);
        }
    } else { $('#wcbox_mobiletab').trigger('click'); }
});
